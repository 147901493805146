<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <!-- <h1 class="text-2xl font-bold">Funds Transfer</h1> -->
      <div class="flex">
        <div
          v-if="viewModal"
          class="fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
        >
          <div
            class="w-1/3 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 mt-5"
          >
            <span
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'FundsTransfer',
                })
              "
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-lg float-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                />
                <path
                  fill-rule="evenodd"
                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                /></svg
            ></span>
            <h4 class="text-center text-xl text-blue font-bold">
              Wallet Transfer
            </h4>
            <div class="justify-center flex-col items-center py-2 px-10">
              <div class="grid grid-cols-2 gap-2">
                <div>
                  <p class="text-xs">Business Name</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Amount</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Business ID</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Payment Reference</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Wallet ID</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Seesion ID</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Transaction Date</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Narration</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Transaction Type</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Email Address</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Source Bank</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Transaction Status</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Destination Bank</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
                <div>
                  <p class="text-xs">Reversal Status</p>
                  <p class="text-lg text-blue font-medium">Dodo Pizza</p>
                </div>
              </div>
            </div>
            <button
              class="bg-blue text-white p-3 rounded-lg align-items-center"
              style="width:inherit; margin:0px auto;"
            >
              Download Receipt
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      payload: {
        id: "",
      },
      viewModal: true,
    };
  },
  computed: {
    // formatDocuments() {
    //   return this.documents;
    // },
  },

  async created() {},
  async mounted() {
    this.isLoading = true;
    let res = await this.$store.dispatch("FETCH_SINGLE_FUNDS_TRANSFER", {
      id: this.$route.params.id,
    });
    // console.log(res.data);
    if (res.status == true) {
      this.isLoading = false;
      this.documents.business_name =
        res.data.BusinessName === "" ? "N/A" : res.data.BusinessName;
      this.documents.merchant_id =
        res.data.BusinessId === "" ? "N/A" : res.data.BusinessId;
      this.documents.date_of_signup = res.data.dateCreated;
      this.documents.last_kyc_update = "";
      this.documents.email_verification = "";
      this.documents.phone_verification = "";
      this.documents.selfie_response = res.data.SelfieResponse.data;
      this.documents.photo = res.data.SelfieRequest.photoUrl;
      this.documents.idType = res.data.SelfieRequest.idType;
      this.documents.selfieVerificationStatus =
        res.data.SelfieResponse.status === ""
          ? "N/A"
          : res.data.SelfieResponse.status;
      this.documents.documentVerificationStatus =
        res.data.DocumentResponse.status === ""
          ? "N/A"
          : res.data.DocumentResponse.status;
    } else {
      this.isLoading = false;
      alert("Sorry, could not find any detail for this record");
    }
  },
  methods: {
    dismissModal(e) {
      const target = e.target;
      if (target.classList.contains("modal")) {
        this.rejectModal = false;
        console.log("Close modal");
      }
    },
  },
};
</script>

<style></style>
